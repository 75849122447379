import { defineStore } from "pinia";
// commented out for now, we will likely move this to notification service
// import { db } from "@/plugins/firebase";
// import { collection, onSnapshot, Unsubscribe } from "firebase/firestore";
import { Unsubscribe } from "firebase/firestore";
import { NotificationState } from "@/types/global";
// commented out for now, we will likely move this to notification service
export const usePaymentLinkNotificationStore = defineStore(
  "paymentLinkNotification",
  {
    state: (): NotificationState => ({
      isVisible: false,
      selectedPayment: null,
      unsubscribe: null as Unsubscribe | null,
      lastNotifiedPaymentTimeStamp: localStorage.getItem(
        "lastNotifiedPaymentTimeStamp"
      ),
    }),
    getters: {
      getVisibility(): boolean {
        return this.isVisible;
      },
    },
    actions: {
      setVisibility(visibility: boolean): void {
        this.isVisible = visibility;
      },

      // listenToPaymentUpdates(accountId: string): void {
      //   if (!accountId) {
      //     return;
      //   }
      //   const paymentsRef = collection(db, "accounts", accountId, "payments");
      //   this.unsubscribe = onSnapshot(paymentsRef, (snapshot) => {
      //     snapshot.docChanges().forEach((change) => {
      //       if (change.type === "added") {
      //         const payment = change.doc.data();
      //         // Retrieve the timestamp from local storage to compare
      //         const lastTimestamp = localStorage.getItem(
      //           "lastNotifiedPaymentTimeStamp"
      //         );
      //         if (!lastTimestamp || payment.created > lastTimestamp) {
      //           this.isVisible = true;
      //           this.selectedPayment = payment;
      //           // Store new timestamp in local storage
      //           localStorage.setItem(
      //             "lastNotifiedPaymentTimeStamp",
      //             payment.created
      //           );
      //           this.lastNotifiedPaymentTimeStamp = payment.created;
      //         } else {
      //           this.isVisible = false;
      //           this.selectedPayment = null;
      //         }
      //       }
      //     });
      //   });
      // },
      // stopListening() {
      //   if (this.unsubscribe) {
      //     this.unsubscribe();
      //     this.unsubscribe = null;
      //   }
      // },
    },
  }
);
