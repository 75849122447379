<template>
  <v-app-bar app flat>
    <v-app-bar-nav-icon
      class="on-primary--text mx-0"
      @click="useMenuStore().toggleIsNavDrawerVisible()"
    ></v-app-bar-nav-icon>
    <v-toolbar color="white">
      <!-- Search bar here eventually -->
      <v-divider
        :thickness="10"
        vertical
        inset
        :color="($route.meta.color || 'lightblue') as string"
        class="mx-2 border-opacity-100"
      />
      <v-row align="center" no-gutters>
        <v-col cols="auto">
          <v-toolbar-title>
            {{ $route.meta.title }}
          </v-toolbar-title>
        </v-col>
        <v-col>
          <v-divider class="mx-3" />
        </v-col>
      </v-row>
      <v-badge
        v-if="cartStore?.items?.length > 0"
        class="mx-3 pt-1"
        :content="sumQuantities()"
        :model-value="cartStore?.items?.length > 0"
        color="red"
        :class="{ 'badge-pulse': cartStore?.isAnimating }"
      >
        <v-btn
          icon="mdi-cart-outline"
          data-cy="cart"
          class="on-primary--text"
          @click="cartStore.toggleIsCartVisible()"
        />
      </v-badge>

      <v-btn
        v-else
        icon="mdi-cart-outline"
        data-cy="cart-nobadge"
        class="on-primary--text mx-0"
        @click="useCartStore().toggleIsCartVisible()"
      />

      <v-menu right>
        <template v-slot:activator="{ props }">
          <v-avatar
            class="primary--text v-sheet--outlined mr-0 pr-0 simple-action-link"
            color="secondary"
            size="40"
            v-bind="props"
          >
            <v-icon
              v-if="!useAuthStore().getAuthInitials"
              large
              color="on-primary"
            >
              mdi-account-outline
            </v-icon>
            <div
              v-if="!!useAuthStore().getAuthInitials"
              class="on-primary--text"
            >
              {{ useAuthStore().getAuthInitials }}
            </div>
          </v-avatar>
          <v-btn
            variant="plain"
            v-bind="props"
            size="small"
            class="text-none text-start mb-1 mr-10"
            append-icon="mdi-menu-down"
          >
            <v-list-item class="pl-0 pr-0">
              <v-list-item-title>
                {{ useAuthStore().getAuthDisplayName }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-capitalize">
                {{ useAuthStore().getRoleName }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-btn>
        </template>
        <v-list class="pt-0 pr-0" dense>
          <v-list-item>
            <v-list-item-title>{{
              useAuthStore().getAuthDisplayName
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              useAuthStore().getAuthEmail
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider />
          <v-list-item @click="useAuthStore().logout()">
            <v-list-item-title>{{ $t("platform.logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
  </v-app-bar>
</template>
<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";
import { useMenuStore } from "@/stores/global";
import { useCartStore } from "@/stores/cart";

const cartStore = useCartStore();
const sumQuantities = () => {
  const items = cartStore.items;
  return items.reduce((total, item) => total + item.quantity, 0);
};
</script>

<style>
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.badge-pulse .v-badge__badge {
  animation: pulse 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
