import { getCurrentAccount } from "@/utils/sessionStorage.utils";
export const hasPlanAccess = (route: { matched: any[] }) => {
  const plan = getCurrentAccount()?.plan || null;
  const accessPlans = route.matched.reduce((acc, r) => {
    const routePlans = Array.isArray(r.meta.plan) ? r.meta.plan : [];
    return [...acc, ...routePlans];
  }, []);

  if (Array.isArray(accessPlans) && accessPlans.length > 0) {
    const hasPlan = accessPlans.includes(plan);
    return hasPlan;
  }
  return true;
};
