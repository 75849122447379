/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { VDataTableServer } from "vuetify/lib/components/index.mjs";
import { VDataTable } from "vuetify/lib/components/index.mjs";
import { VSkeletonLoader } from "vuetify/lib/components/index.mjs";
import { VDatePicker } from "vuetify/lib/components/index.mjs";
import { VStepper } from "vuetify/lib/components/index.mjs";
import { VNavigationDrawer } from "vuetify/lib/components/index.mjs";
import { VCard } from "vuetify/lib/components/index.mjs";
import { VCardItem } from "vuetify/lib/components/index.mjs";
import { VCardText } from "vuetify/lib/components/index.mjs";
import { VCardTitle } from "vuetify/lib/components/index.mjs";
import { VCardActions } from "vuetify/lib/components/index.mjs";
import { VCardSubtitle } from "vuetify/lib/components/index.mjs";
import { VSelect } from "vuetify/lib/components/index.mjs";
import { VImg } from "vuetify/lib/components/index.mjs";
import { VAvatar } from "vuetify/lib/components/index.mjs";
import { VIcon } from "vuetify/lib/components/index.mjs";
import { VContainer } from "vuetify/lib/components/index.mjs";
import { VBtn } from "vuetify/lib/components/index.mjs";
import { VCol } from "vuetify/lib/components/index.mjs";
import { VRow } from "vuetify/lib/components/index.mjs";
import { VList } from "vuetify/lib/components/index.mjs";
import { VListItem } from "vuetify/lib/components/index.mjs";
import { VSnackbar } from "vuetify/lib/components/index.mjs";
import { VChip } from "vuetify/lib/components/index.mjs";
import { VProgressLinear } from "vuetify/lib/components/index.mjs";
import { VDialog } from "vuetify/lib/components/index.mjs";
import { VToolbar } from "vuetify/lib/components/index.mjs";
import { VLayout } from "vuetify/lib/components/index.mjs";
import { VOverlay } from "vuetify/lib/components/index.mjs";
import { VHover } from "vuetify/lib/components/index.mjs";
import { VCarousel } from "vuetify/lib/components/index.mjs";
import { VCarouselItem } from "vuetify/lib/components/index.mjs";
import { VMenu } from "vuetify/lib/components/index.mjs";
import { VCheckbox } from "vuetify/lib/components/index.mjs";
import { VSpacer } from "vuetify/lib/components/index.mjs";
import { VTextField } from "vuetify/lib/components/index.mjs";
import { VMain } from "vuetify/lib/components/index.mjs";
import { VSheet } from "vuetify/lib/components/index.mjs";
import { VTab } from "vuetify/lib/components/index.mjs";
import { VWindow } from "vuetify/lib/components/index.mjs";
import { VWindowItem } from "vuetify/lib/components/index.mjs";
import { VForm } from "vuetify/lib/components/index.mjs";
import { VSwitch } from "vuetify/lib/components/index.mjs";
import { VTimeline } from "vuetify/lib/components/index.mjs";
import { VTimelineItem } from "vuetify/lib/components/index.mjs";
import { VExpansionPanel } from "vuetify/lib/components/index.mjs";
import { VExpansionPanels } from "vuetify/lib/components/index.mjs";
import { VExpansionPanelTitle } from "vuetify/lib/components/index.mjs";
import { VExpansionPanelText } from "vuetify/lib/components/index.mjs";
import { VRadioGroup } from "vuetify/lib/components/index.mjs";
import { VRadio } from "vuetify/lib/components/index.mjs";
import { VTooltip } from "vuetify/lib/components/index.mjs";
import { VCombobox } from "vuetify/lib/components/index.mjs";
import { VListItemTitle } from "vuetify/lib/components/index.mjs";
import { VInput } from "vuetify/lib/components/index.mjs";
import { VProgressCircular } from "vuetify/lib/components/index.mjs";
import { VAppBar } from "vuetify/lib/components/index.mjs";
import { VApp } from "vuetify/lib/components/index.mjs";
import { VAppBarNavIcon } from "vuetify/lib/components/index.mjs";
import { VDivider } from "vuetify/lib/components/index.mjs";

// Composables
import { createVuetify } from "vuetify";

export default createVuetify({
  components: {
    VDataTable,
    VDataTableServer,
    VSkeletonLoader,
    VDatePicker,
    VStepper,
    VCard,
    VNavigationDrawer,
    VCardItem,
    VCardText,
    VCardTitle,
    VCardActions,
    VCardSubtitle,
    VSelect,
    VImg,
    VAvatar,
    VIcon,
    VBtn,
    VContainer,
    VCol,
    VRow,
    VList,
    VListItem,
    VSnackbar,
    VChip,
    VProgressLinear,
    VDialog,
    VToolbar,
    VLayout,
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VOverlay,
    VHover,
    VCarousel,
    VCarouselItem,
    VMenu,
    VCheckbox,
    VSpacer,
    VTextField,
    VMain,
    VSheet,
    VTab,
    VWindow,
    VWindowItem,
    VForm,
    VSwitch,
    VTimeline,
    VTimelineItem,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelTitle,
    VExpansionPanelText,
    VRadioGroup,
    VRadio,
    VTooltip,
    VCombobox,
    VListItemTitle,
    VInput,
    VProgressCircular,
    VDivider,
  },
  defaults: {
    VDataTable: {
      density: "compact",
      variant: "outlined",
    },
    VProgressCircular: {},
    VListItemTitle: {},
    VInput: {
      density: "compact",
      variant: "outlined",
    },
    VDivider: {},
    VTooltip: {},
    VRadio: {},
    VRadioGroup: {},
    VExpansionPanel: {},
    VExpansionPanels: {},
    VExpansionPanelTitle: {},
    VExpansionPanelText: {},
    VTimeline: {},
    VTimelineItem: {},
    VSwitch: {
      density: "compact",
      variant: "outlined",
    },
    VForm: {},
    VWindow: {},
    VWindowItem: {},
    VTab: {},
    VSheet: {},
    VMain: {},
    VSpacer: {},
    VCheckbox: {},
    VCarousel: {},
    VCarouselItem: {},
    VMenu: {},
    VHover: {},
    VOverlay: {},
    VApp: {},
    VAppBar: {},
    VAppBarNavIcon: {},
    VLayout: {},
    VToolbar: {},
    VDialog: {},
    VProgresslinear: {},
    VChip: {},
    VSnackbar: {},
    VListItem: {
      density: "compact",
    },
    VList: {
      density: "compact",
    },
    VRow: {},
    VCol: {},
    VContainer: {},
    VAvatar: {},
    VImg: {},
    VIcon: {},
    VCardText: {},
    VCardTitle: {},
    VCardSubtitle: {},
    VCardItem: {},
    VNavigationDrawer: {
      density: "compact",
    },
    VTextField: {
      density: "compact",
      variant: "outlined",
    },
    VSelect: {
      density: "compact",
      variant: "outlined",
    },
    VCombobox: {
      density: "compact",
      variant: "outlined",
    },
    VTextarea: {
      density: "compact",
      variant: "outlined",
    },
    VDatePicker: {},
    VCard: {
      rounded: "lg",
      flat: true,
    },
    VSkeletonLoader: {
      elevation: "0",
    },
    VBtn: {
      rounded: "pill",
    },
  },

  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          background: "#eeeeee",
          primaryLight: "#eaeaea",
          primary: "#5c606b",
          onprimary: "#ffffff",
          secondary: "#ffca38",
          accent: "#82B1FF",
          error: "#ffc3c3",
          info: "#cff5f6",
          success: "#d7f7c2",
          warning: "#fcedb9",
          lightblue: "#c7ebff",
        },
      },
    },
  },
});
