import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { hasPlanAccess } from "@/utils/plan.utils";

export default async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  if (hasPlanAccess(to)) {
    next();
  } else {
    next({ name: "dashboard" });
  }
};
