import { defineStore, getActivePinia } from "pinia";
import i18n from "@/plugins/i18n";
import moment from "moment-timezone";
import axios from "@/plugins/axios";
import { LoginUser, AuthState } from "@/types/login";
import {
  applyActionCode,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
  UserCredential,
} from "firebase/auth";
import router from "@/router";
import { AxiosError, AxiosResponse } from "axios";
import {
  ChangeSessionPayload,
  CreateUser,
  CreateAccount,
} from "@/types/register";
import { Navigator as CustomNavigator } from "@/types/global";
import { useSnackBarStore } from "@/stores/snackbar";
import { useAccountStore } from "@/stores/account";
import { db } from "@/plugins/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { NectarStatus, AccountPlan } from "@/enums/status.enum";
import { useTestClockStore } from "./testClock";
import {
  setCurrentAccount,
  removeCurrentAccount,
  updateCurrentAccount,
} from "@/utils/sessionStorage.utils";

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    user: null,
    userId: "",
    authUser: null,
    loading: false,
    logoutLoading: false,
    changeSessionLoading: false,
    verified: false,
    realTimeFavReaders: {},
    accountStatus: "",
    accountPlan: AccountPlan.STARTER,
    testClock: null,
    simulatedCurrentTime: 0,
    isTestEnv: false,
  }),
  getters: {
    isLoading: (state) => state.loading,
    isVerified: (state) => state.verified,
    getUserId: (state: any) => {
      return state.userId;
    },
    getCustomer: (state: any) => {
      try {
        const customAttributes = JSON.parse(
          state.authUser?.reloadUserInfo?.customAttributes
        );
        const customer = customAttributes?.customer;
        return customer;
      } catch (error) {
        return null;
      }
    },
    getAuthInitials: (state) => {
      const authName = state.authUser?.displayName || "";

      const getInitials = (name: string) => {
        const parts = name.split(" ") ?? [];
        let initials;

        if (parts.length > 1) {
          const first = (parts.shift() ?? "").charAt(0);
          const last = (parts.pop() ?? "").charAt(0);
          initials = `${first}${last}`.trim();
        } else {
          initials = name.substring(0, 2);
        }

        return initials.toUpperCase();
      };

      return getInitials(authName);
    },
    getRoleName: (state): string => {
      return state.user?.accounts
        ? state.user?.accounts[useAccountStore().account?.id || ""]?.role
        : "staff";
    },
    getAuthDisplayName: (state) => state.authUser?.displayName,
    getAuthEmail: (state) => state.authUser?.email,
    isLoggedIn: (state): boolean => {
      return (
        !!state.user &&
        !!state.authUser &&
        Object.keys(state.user?.accounts as any).length > 0
      );
    },
    isStaff: (state: any) => {
      return state.getRoleName === "staff";
    },
    isOwner: (state: any) => {
      return state.getRoleName === "owner";
    },
    getUserAccounts: (state) => {
      return state.user?.accounts || {};
    },
    getAccountPlan: (state) => {
      return state.accountPlan;
    },
  },
  actions: {
    async processToken({ token }: { token: any }): Promise<boolean> {
      return axios
        .post(`/token/${token}/process`)
        .then(() => {
          return true;
        })
        .catch((e: any) => {
          useSnackBarStore().toastError({ message: e.error });

          return false;
        });
    },
    async fetchTokenInfo({ token }: { token: any }): Promise<AxiosResponse> {
      return axios.get(`/publicApi/token/${token}/info`);
    },
    needToVerifyEmail(): boolean {
      const auth = getAuth();

      return !!auth.currentUser && !auth.currentUser.emailVerified;
    },
    async logout(): Promise<void> {
      this.logoutLoading = true;
      this.authUser = null;
      this.user = null;
      useAccountStore().setCurrentAccount(null);
      await signOut(getAuth());
      await router.push({ name: "login" });
      removeCurrentAccount();
      // clear all stores before leave
      (getActivePinia() as any)._s.forEach((store: any) => store.$reset());
      this.logoutLoading = false;
    },

    async login(payload: LoginUser): Promise<void> {
      this.loading = true;
      const { email, password, token, persist } = payload;
      const auth = getAuth();

      if (persist) {
        await setPersistence(auth, browserLocalPersistence);
      }
      const user = await signInWithEmailAndPassword(auth, email, password)
        .then((userCredentials: UserCredential) => {
          return userCredentials.user;
        })
        .catch((error) => {
          this.loading = false;
          if (error.message.includes("wrong-password")) {
            useSnackBarStore().toastError({
              message: i18n.global.t("page.login.wrongPassword"),
            });
          } else if (error.message.includes("user-not-found")) {
            useSnackBarStore().toastError({
              message: i18n.global.t("page.login.couldNotFindAccount"),
            });
          } else {
            useSnackBarStore().toastError({
              message: error.message,
            });
          }
        });

      if (user && user.emailVerified) {
        useSnackBarStore().toastSuccess({
          message: i18n.global.t("page.login.success"),
        });
        if (token) await this.processToken({ token });
        await this.afterLogin();
        this.loading = false;
      } else if (user && !user.emailVerified) {
        sendEmailVerification(user);
        useSnackBarStore().toastSuccess({
          message: i18n.global.t("page.login.emailVerificationSent"),
        });
        this.loading = false;
      }
    },

    loginWithGoogle(): void {
      const provider = new GoogleAuthProvider();

      signInWithPopup(getAuth(), provider)
        .then(async () => {
          this.loading = true;
          const token = router.currentRoute.value.query?.token;
          if (token) await this.processToken({ token });
          await this.fetchAndSetUser();

          // The user exist and is ok for login
          if (this.user?.accounts) {
            useSnackBarStore().toastSuccess({
              message: i18n.global.t("page.login.success"),
            });
            await this.afterLogin();
            this.loading = false;
          } else {
            // The firestore user not exist, create one as owner and ask to create the account
            await this.updateUser({ owner: true, role: "owner" });
            await this.fetchAndSetUser();
          }
        })
        .catch(() => {});
    },

    async afterLogin(): Promise<void> {
      await this.fetchAndSetUser();
      this.authUser = getAuth().currentUser;
      const currentAccount = useAccountStore().account;
      this.accountStatus = currentAccount?.nectarStatus;

      if (router.currentRoute.value.name === "resetpassword") {
        return;
      }
      // Handle account status-based routing here because status guard running before this method, status is not updated
      if (
        router.currentRoute.value.name === "accountSetup" ||
        (router.currentRoute.value.name === "login" &&
          this.accountStatus === NectarStatus.ACTIVE &&
          this.isLoggedIn)
      ) {
        router.push({ name: "dashboard" });
        return;
      } else if (this.accountStatus === NectarStatus.NEW) {
        router.push({ name: "accountSetup" });
        return;
      }

      //other routing scenarios
      if (!this.isLoggedIn) {
        router.push({ name: "login" });
        return;
      }

      if (Object.keys(this.user?.accounts || {}).length === 0) {
        router.push({ name: "register", query: { step: 3 } });
        return;
      }

      if (this.needToVerifyEmail() || !this.user) {
        router.push({
          name: "register",
          query: router.currentRoute.value.query,
        });
        return;
      }
      //default routing
      const redirectName =
        router.currentRoute.value.redirectedFrom?.name ||
        router.currentRoute.value.name
          ?.toString()
          .replace("register", "dashboard");
      router.push({
        name: redirectName,
        query: router.currentRoute.value?.redirectedFrom?.query,
        params: router.currentRoute.value?.redirectedFrom?.params,
      });
    },

    setUser(userData: any) {
      this.user = userData;
    },

    setAccountPlan(accountPlan: any) {
      this.accountPlan = accountPlan;
    },

    async fetchAndSetUser(): Promise<void> {
      const auth = getAuth();
      if (!auth.currentUser) {
        return;
      }

      const doFetchMe = async () => {
        // Performs a refresh
        this.loading = true;
        await auth.currentUser?.getIdTokenResult(true);
        const response = await axios.get("/auth/me");
        if (response) {
          const { data } = response;
          if (data?.nextAccountId) {
            await this.changeSession({ accountId: data.nextAccountId });
            return;
          }
          this.setUser(data?.user);
          if (data.currentAccount) {
            this.setAccountPlan(data.currentAccount.accountPlan || null);
          } else {
            // If no current account exists, handle it as a new user
            this.loading = false;
            this.user = null;
            router.push({ name: "register", query: { step: 3 } });
            return;
          }
          const activateIds = Object.keys(data.user.accounts);
          // The user doesn't have any account, it's new, null store user and ask for account creation
          // if account is inactivated, logged out the user and back to login page
          if (
            !this.user?.accounts ||
            Object.keys(this.user?.accounts as any).length === 0 ||
            !activateIds.includes(data.currentAccount.id)
          ) {
            this.loading = false;
            this.user = null;
            router.push({ name: "register", query: { step: 3 } });
          } else {
            this.userId = auth?.currentUser?.uid || "";
            // set current account in session storage for role and plan guard
            const account = {
              role: data?.user?.accounts[data?.currentAccount?.id]?.role,
              plan: data?.currentAccount?.accountPlan,
              accessStatus: data?.currentAccount?.nectarStatus,
            };
            setCurrentAccount(account);
            useAccountStore().setCurrentAccount(data.currentAccount);
            this.testClock =
              data.user.accounts[data.currentAccount.id]?.testClock;
            if ((this.testClock, length)) {
              const testClockData = await useTestClockStore().retrieve(
                this.testClock
              );
              this.simulatedCurrentTime = testClockData?.frozen_time;
            }
            // Initialize the listener
            this.listenToFavReaders(this.userId);
            this.listenToAccountPlan(data.currentAccount.id);
          }
          this.loading = false;
        }
      };

      if (auth.currentUser?.emailVerified) {
        await doFetchMe();
      } else {
        const checkForVerifiedInterval = setInterval(() => {
          auth.currentUser?.reload().then(async () => {
            if (auth.currentUser?.emailVerified) {
              this.verified = true;
              await doFetchMe();
              clearInterval(checkForVerifiedInterval);
            }
          });
        }, 2000);
      }
    },
    async setUserId(id: string): Promise<void> {
      this.userId = id;
    },
    async updateUser(claims?: any): Promise<void> {
      const navigator: CustomNavigator = window.navigator;
      const timezone = moment.tz.guess();
      const language = navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator.userLanguage;
      const payload = {
        timezone,
        language,
        claims,
      };
      const response = await axios.patch("/auth/me", payload);
      this.user = response.data.user;
      useAccountStore().setCurrentAccount(response.data.currentAccount);
      // update current account in session storage for role and plan guard
      const account = {
        role: response?.data?.user?.accounts[response?.data?.currentAccount?.id]
          ?.role,
        plan: response?.data?.currentAccount?.accountPlan,
        accessStatus: response?.data?.currentAccount?.nectarStatus,
      };
      setCurrentAccount(account);
    },
    async verifyEmailLink(): Promise<boolean> {
      const auth = getAuth();

      let res = false;
      if (auth.currentUser) {
        res = await sendEmailVerification(auth.currentUser)
          .then(() => {
            return true;
          })
          .catch((error) => {
            useSnackBarStore().toastError({ message: error });
            return false;
          });
      }

      return res;
    },
    async register(payload: CreateUser): Promise<boolean> {
      const { displayName, email, password, token } = payload;
      try {
        await this.createAuthUser({
          displayName,
          email,
          password,
          token,
        });

        await this.fetchAndSetUser();
        return true;
      } catch (error) {
        return false;
      }
    },
    async createAuthUser(payload: CreateUser): Promise<void> {
      const { email, password, displayName, token } = payload;
      const auth = getAuth();

      if (!password || password.length < 8) {
        useSnackBarStore().toastError({
          message: i18n.global.t("errors.password"),
        });
        return;
      }
      if (!displayName) {
        useSnackBarStore().toastError({
          message: i18n.global.t("errors.authName"),
        });
        return;
      }
      const onError = (error: any) => {
        this.loading = false;
        if ("auth/email-already-in-use" === error.code) {
          useSnackBarStore().toastError({
            message: i18n.global.t("errors.emailExists"),
          });
          router.push({ name: "login", query: { email } });
          throw new Error();
        } else {
          useSnackBarStore().toastError({
            message: i18n.global.t(`errors.${error.message}`),
          });
        }
      };

      try {
        // 1. Create the Firebase Auth user
        await createUserWithEmailAndPassword(auth, email, password);

        if (!auth.currentUser) {
          return;
        }
        let updateUserClaims: any = { owner: true, role: "owner" };

        // 2. If user was created with a token, process the token before we fetch
        if (token) {
          const isProcessOk = await this.processToken({ token });
          if (!isProcessOk) return;

          // Force the token refresh so that the auth token has the emailVerified param set to true
          // from the processToken call
          await auth.currentUser?.reload();
          updateUserClaims = null;
        }

        // 3. Update the Firebase Auth user displayName value to their provided name
        await updateProfile(auth.currentUser, { displayName });

        // 4. Update the database user with browser preferences (timezone & language)
        // and owner claims first user register always be owner of the account
        await this.updateUser(updateUserClaims);

        // 5. Send email verification
        if (!token) this.verifyEmailLink();
        if (token) this.afterLogin();

        this.loading = false;
      } catch (e: any) {
        onError(e);
      }
    },
    async createAccount(data: CreateAccount): Promise<void> {
      const name = data.name;
      const country = data.country;

      await axios
        .post("/account", { name, country })
        .then(
          (response: AxiosResponse) =>
            (useAccountStore().account = response.data)
        )
        .catch((error: AxiosResponse) => {
          useSnackBarStore().toastError({
            message: error.data,
          });
        })
        .finally(() => {
          this.afterLogin();
          useSnackBarStore().toastSuccess({
            message: i18n.global.t("accounts.accountCreated"),
          });
          router.push({ name: "dashboard" });
        });
    },
    async changeSession(payload: ChangeSessionPayload): Promise<void> {
      const { accountId } = payload || {};
      this.changeSessionLoading = true;

      // Update our session to prepare for SSO
      await axios
        .post("/auth/session", {
          account: accountId,
        })
        .then(async () => {
          router.push({ name: "dashboard" });
          await this.afterLogin();
        })
        .catch((error: AxiosResponse) => {
          useSnackBarStore().toastError({ message: error.data });
        })
        .finally(() => {
          this.changeSessionLoading = false;
        });
    },
    async resetPassword(payload: { email: any }): Promise<boolean> {
      const { email } = payload;
      const auth = getAuth();

      return await sendPasswordResetEmail(auth, email)
        .then(() => {
          return true;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((error: AxiosError) => {
          useSnackBarStore().toastError({
            message: i18n.global.t("page.resetPassword.error"),
          });
          return false;
        });
    },
    async confirmEmailVerification(oobCode: any): Promise<boolean> {
      const auth = getAuth();

      return await applyActionCode(auth, oobCode)
        .then(() => {
          return true;
        })
        .catch((error) => {
          useSnackBarStore().toastError({ message: error });
          return false;
        });
    },

    async listenToFavReaders(userId: string): Promise<any> {
      if (!userId) {
        return;
      }
      const userRef = doc(db, "users", userId);
      onSnapshot(userRef, (doc) => {
        const user = doc.data();
        this.realTimeFavReaders = user?.favoriteReaders || {};
      });
    },
    getFavTerminals(accountId: string) {
      return this.realTimeFavReaders[accountId];
    },

    //subscribe to listen plan changes
    async listenToAccountPlan(accountId: string): Promise<any> {
      if (!accountId) {
        return;
      }

      const accountRef = doc(db, "accounts", accountId);

      onSnapshot(accountRef, (doc) => {
        const account: any = doc.data();
        this.accountPlan =
          account?.subscription?.productName || AccountPlan.STARTER;
        const planCanceled = account?.subscription?.status === "canceled";
        const simulatedTimestamp = moment.unix(this.simulatedCurrentTime);
        const currentTimestamp = moment();
        const subscriptionEndTimestamp = moment.unix(
          account?.subscription?.current_period_end
        );

        //check if plan ended, if test clock is setup, use the test clock time, else use current time
        if (
          simulatedTimestamp &&
          subscriptionEndTimestamp.isBefore(simulatedTimestamp) &&
          planCanceled
        ) {
          this.accountPlan = AccountPlan.STARTER;
          const newAccount = {
            plan: AccountPlan.STARTER,
          };
          updateCurrentAccount(newAccount);
          return;
        } else if (
          planCanceled &&
          subscriptionEndTimestamp.isBefore(currentTimestamp)
        ) {
          this.accountPlan = AccountPlan.STARTER;
          const newAccount = {
            plan: AccountPlan.STARTER,
          };
          updateCurrentAccount(newAccount);
          return;
        }
      });
    },
  },
});
