import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import i18n from "@/plugins/i18n";

export default async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  document.title = import.meta.env.VITE_TITLE.concat(" | ").concat(
    i18n.global.t(`navBar.${to.name?.toString()}`)
  );

  next();
};
