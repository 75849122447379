<template>
  <v-navigation-drawer
    v-model="useCartStore().isVisible"
    app
    location="right"
    width="600"
    temporary
  >
    <v-list lines="three">
      <v-list-item>
        <v-list-item-title class="text-h6">{{
          $t("common.cart.cart")
        }}</v-list-item-title>
      </v-list-item>

      <v-divider class="mb-2" />

      <template v-if="cartItems?.length > 0">
        <transition-group name="slide-fade">
          <v-list-item
            class="my-1"
            v-for="(item, index) in cartItems"
            :key="index"
            data-cy="cart-item"
          >
            <template v-slot:prepend>
              <v-img
                :src="item.image ? item.image : default_product_image"
                :width="120"
                :height="120"
                contain
                class="flex-shrink-0 rounded mr-3"
              />
            </template>

            <v-list-item>
              <v-list-item-title>
                <v-row>
                  <v-col cols="12" class="d-flex justify-space-between">
                    <span class="text-h6 text-black font-weight-medium">{{
                      item.name
                    }}</span>
                    <span class="text-h6 text-black font-weight-medium">
                      ${{ ((item.price / 100) * item.quantity).toFixed(2) }}
                    </span>
                  </v-col>
                </v-row>
              </v-list-item-title>

              <v-list-item-subtitle>
                <v-row>
                  <v-col cols="12" class="d-flex justify-space-between">
                    <span>{{ item.priceName }}</span>
                    <span>ea. ${{ (item.price / 100).toFixed(2) }}</span>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>

              <span class="my-2 d-block">
                {{ item.description }}
              </span>

              <v-list-item-action class="d-flex mt-2">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col class="d-flex align-center">
                    <v-btn
                      icon="mdi-minus"
                      data-cy="cart-minus"
                      size="compact"
                      color="primary"
                      :disabled="item.quantity === 1"
                      @click="decreaseQuantity(item.id, item.priceId)"
                    />
                    <span
                      data-cy="cart-item-quantity"
                      class="mx-2 text-body-1"
                      >{{ item.quantity }}</span
                    >
                    <v-btn
                      color="primary"
                      data-cy="cart-plus"
                      icon="mdi-plus"
                      size="compact"
                      @click="increaseQuantity(item.id, item.priceId)"
                    />
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      icon="mdi-delete-outline"
                      color="red"
                      variant="text"
                      @click="useCartStore().removeItem(item.id)"
                    ></v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list-item>
        </transition-group>
        <v-divider class="mt-2"></v-divider>

        <v-list-item>
          <v-list-item-title class="text-h6"
            >{{ $t("common.action.total") }}: ${{
              (cartTotal / 100).toFixed(2)
            }}</v-list-item-title
          >
        </v-list-item>

        <v-list-item>
          <v-col>
            <v-btn
              prepend-icon="mdi-shield-lock"
              class="mb-2"
              block
              color="blue"
              @click="checkout"
              :loading="cartStore.loading"
              :disabled="cartStore.loading"
              >{{ $t("common.cart.checkout") }}</v-btn
            >
            <v-btn
              data-cy="cart-reset"
              block
              variant="tonal"
              @click="useCartStore().resetCart()"
              >{{ $t("common.cart.clearCart") }}</v-btn
            >
          </v-col>
        </v-list-item>
      </template>

      <v-list-item v-else>
        <v-list-item-title>{{ $t("common.cart.noItems") }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useCartStore } from "@/stores/cart";
import default_product_image from "@/assets/default_product_image.png";

const cartStore = useCartStore();
const cartItems = computed(() => cartStore.items);
const cartTotal = computed(() => cartStore.total);

const increaseQuantity = (itemId: string, priceId: string) => {
  cartStore.updateItemQuantity(itemId, 1, priceId);
};
const decreaseQuantity = (itemId: string, priceId: string) => {
  cartStore.updateItemQuantity(itemId, -1, priceId);
};
const checkout = async () => {
  await useCartStore().checkoutCart();
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
</style>
