// Composable
import { createRouter, createWebHistory } from "vue-router";
import authGuard from "./authGuard";
import roleGuard from "./roleGuard";
import pageTitleGuard from "./pageTitleGuard";
import i18n from "@/plugins/i18n";
import { UserRoleStatus, AccountPlan, NectarStatus } from "@/enums/status.enum";
import accountStatusGuard from "./accountStatusGuard";
import planGuard from "./planGuard";

const routes = [
  {
    path: "/:catchAll(.*)",
    redirect: "login",
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: i18n.global.t("navBar.login"),
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginPage.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      title: i18n.global.t("navBar.register"),
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/RegisterPage.vue"),
  },
  {
    path: "/invite",
    name: "invite",
    meta: {
      title: i18n.global.t("navBar.invite"),
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "invite" */ "@/views/InvitePage.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: i18n.global.t("navBar.dashboard"),
      color: "secondary",
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/DashboardPage.vue"),
  },
  {
    path: "/account-setup",
    name: "accountSetup",
    meta: {
      title: i18n.global.t("navBar.accountSetup"),
      color: "secondary",
      activeAccountsOnly: false,
      requiresAuth: true,
      accessStatus: [NectarStatus.NEW],
    },
    component: () =>
      import(
        /* webpackChunkName: "accountSetup" */ "@/views/AccountSetupPage.vue"
      ),
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    meta: {
      title: i18n.global.t("navBar.resetPassword"),
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/ResetPasswordPage.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: {
      title: i18n.global.t("navBar.users"),
      requiresAuth: true,
      accessRoles: [UserRoleStatus.OWNER, UserRoleStatus.ADMIN],
      accessStatus: [NectarStatus.ACTIVE],
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/UserListPage.vue"),
  },
  {
    path: "/readers",
    name: "readers",
    meta: {
      title: i18n.global.t("navBar.readers"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
    },
    component: () =>
      import(
        /* webpackChunkName: "readers" */ "@/views/LocationsAndTerminalsPage.vue"
      ),
  },
  {
    path: "/readers/detail/:locationId",
    name: "readerDetail",
    meta: {
      title: i18n.global.t("navBar.readers"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
    },
    component: () =>
      import(
        /* webpackChunkName: "readerDetail" */ "@/views/ReaderDetailsPage.vue"
      ),
  },
  {
    path: "/account",
    name: "account",
    meta: {
      title: i18n.global.t("navBar.account"),
      requiresAuth: true,
      accessRoles: [UserRoleStatus.OWNER],
      accessStatus: [NectarStatus.ACTIVE],
    },
    component: () =>
      import(/* webpackChunkName: "account" */ "@/views/AccountPage.vue"),
  },
  {
    path: "/account/settings",
    name: "settings",
    meta: {
      title: i18n.global.t("navBar.settings"),
      requiresAuth: true,
      accessRoles: [UserRoleStatus.OWNER],
      accessStatus: [NectarStatus.ACTIVE],
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/SettingPage.vue"),
  },
  {
    path: "/payments",
    name: "payments",
    meta: {
      title: i18n.global.t("navBar.payments"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
    },
    component: () =>
      import(/* webpackChunkName: "payments" */ "@/views/PaymentListPage.vue"),
  },
  {
    path: "/create/payment",
    name: "createPayment",
    meta: {
      title: i18n.global.t("navBar.payments"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
    },
    component: () =>
      import(
        /* webpackChunkName: "processPayment" */ "@/views/PaymentProcessPage.vue"
      ),
  },
  {
    path: "/create/product",
    name: "createProduct",
    meta: {
      title: i18n.global.t("navBar.createProduct"),
      requiresAuth: true,
      accessRoles: [UserRoleStatus.OWNER, UserRoleStatus.ADMIN],
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(
        /* webpackChunkName: "createProduct" */ "@/views/ProductCreatePage.vue"
      ),
  },
  {
    path: "/products",
    name: "products",
    meta: {
      title: i18n.global.t("navBar.products"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(/* webpackChunkName: "products" */ "@/views/ProductListPage.vue"),
  },
  {
    path: "/product/:id",
    name: "productDetails",
    meta: {
      title: i18n.global.t("navBar.productDetails"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(
        /* webpackChunkName: "productDetails" */ "@/views/ProductDetailsPage.vue"
      ),
  },
  {
    path: "/payment/:id",
    name: "processPayment",
    meta: {
      title: i18n.global.t("navBar.payments"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
    },
    component: () =>
      import(
        /* webpackChunkName: "processPayment" */ "@/views/PaymentProcessPage.vue"
      ),
  },
  {
    path: "/payments/details/:paymentIntentId",
    name: "paymentDetails",
    meta: {
      title: i18n.global.t("navBar.payments"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
    },
    component: () =>
      import(
        /* webpackChunkName: "paymentDetails" */ "@/views/PaymentDetailsPage.vue"
      ),
  },
  {
    path: "/emailRedirect",
    name: "emailRedirect",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "emailRedirect" */ "@/views/EmailRedirectPage.vue"
      ),
  },
  {
    path: "/customers",
    name: "customers",
    meta: {
      title: i18n.global.t("navBar.customers"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(/* webpackChunkName: "customers" */ "@/views/CustomersPage.vue"),
  },
  {
    path: "/customer/:id",
    name: "customerDetails",
    meta: {
      title: i18n.global.t("navBar.customerDetails"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(
        /* webpackChunkName: "customerDetails" */ "@/views/CustomerDetailsPage.vue"
      ),
  },
  {
    path: "/paymentLinks",
    name: "paymentLinks",
    meta: {
      title: i18n.global.t("navBar.paymentLinks"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(
        /* webpackChunkName: "paymentLinks" */ "@/views/PaymentLinksPage.vue"
      ),
  },
  {
    path: `/create/paymentLinks`,
    name: "createPaymentLink",
    meta: {
      title: i18n.global.t("navBar.createPaymentLink"),
      requiresAuth: true,
      accessRoles: [UserRoleStatus.OWNER, UserRoleStatus.ADMIN],
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(
        /* webpackChunkName: "paymentLinksCreate" */ "@/views/PaymentLinksCreateAndUpdatePage.vue"
      ),
  },
  {
    path: `/paymentLinks/:id`,
    name: "updatePaymentLink",
    meta: {
      title: i18n.global.t("navBar.updatePaymentLink"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(
        /* webpackChunkName: "updatePaymentLink" */ "@/views/PaymentLinksCreateAndUpdatePage.vue"
      ),
  },
  {
    path: `/details/paymentLinks/:id`,
    name: "paymentLinksDetail",
    meta: {
      title: i18n.global.t("navBar.paymentLinksDetail"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(
        /* webpackChunkName: "paymentLinksDetail" */ "@/views/PaymentLinksDetailPage.vue"
      ),
  },
  {
    path: `/terminalForms`,
    name: "terminalForms",
    meta: {
      title: i18n.global.t("navBar.terminalForms"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(
        /* webpackChunkName: "terminalForms" */ "@/views/TerminalFormsListPage.vue"
      ),
  },
  {
    path: `/terminalForms/create`,
    name: "terminalFormsCreate",
    meta: {
      title: i18n.global.t("navBar.terminalForms"),
      requiresAuth: true,
      accessRoles: [UserRoleStatus.OWNER, UserRoleStatus.ADMIN],
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(
        /* webpackChunkName: "terminalFormsCreate" */ "@/views/TerminalFormCreateAndUpdatePage.vue"
      ),
  },
  {
    path: `/terminalForms/:id`,
    name: "terminalFormsUpdate",
    meta: {
      title: i18n.global.t("navBar.terminalForms"),
      requiresAuth: true,
      accessStatus: [NectarStatus.ACTIVE],
      plan: [AccountPlan.LITE],
    },
    component: () =>
      import(
        /* webpackChunkName: "terminalFormsUpdate" */ "@/views/TerminalFormCreateAndUpdatePage.vue"
      ),
  },
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(pageTitleGuard);
router.beforeEach(authGuard);
router.beforeEach(roleGuard);
router.beforeEach(accountStatusGuard);
router.beforeEach(planGuard);
export default router;
