export enum TerminalStatus {
  ONLINE = "online",
  OFFLINE = "offline",
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum PaymentStatus {
  SUCCEEDED = "succeeded",
  CANCELED = "canceled",
  FAILED = "failed",
  PARTIALLY_REFUNDED = "partially_refunded",
  REFUNDED = "refunded",
  REQUIRES_PAYMENT_METHOD = "requires_payment_method",
  REQUIRES_ACTION = "requires_action",
  PROCESSING = "processing",
  INCOMPLETE = "incomplete",
  REQUIRES_CONFIRMATION = "requires_confirmation",
  PAID = "paid",
}

export enum UserRoleStatus {
  OWNER = "owner",
  ADMIN = "admin",
  STAFF = "staff",
}

export enum UserActiveStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  INVITED = "Invited",
}

export enum PlanTypeStatus {
  STARTER = "Free",
  LITE = "Lite",
  PRO = "Pro",
}

export enum StripeAccountStatus {
  UNKNOWN = "unknown",
  RESTRICTED = "restricted",
  COMPLETE = "complete",
  ENABLED = "enabled",
  RESTRICTED_SOON = "restricted_soon",
  REJECTED = "rejected",
  PENDING_ENABLED = "pending_enabled",
  PENDING_DISABLED = "pending_disabled",
}

export enum AccountSetupStatus {
  INACTIVE = "Inactive",
  PAYMENTSENABLED = "Payments Enabled",
  COMPLETE = "Complete",
}

export enum InvoiceStatus {
  PAID = "paid",
  DRAFT = "draft",
  PAST_DUE = "past_due",
  SCHEDULED = "scheduled",
  VOID = "void",
  OPEN = "open",
  FAILED = "failed",
  UNCOLLECTIBLE = "uncollectible",
}

export enum NectarStatus {
  NEW = "new",
  ACTIVE = "active",
}

export enum PaymentLinkStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum AccountPlan {
  LITE = "lite",
  STARTER = "starter",
}

export enum TerminalFormStatus {
  ACTIVE = "active",
  ARCHIVED = "archived",
  COMPLETE = "complete",
  FORM_INCOMPLETE = "form incomplete",
}
