<template>
  <v-navigation-drawer
    color="primaryLight"
    v-model="useMenuStore().isVisible"
    app
  >
    <template v-slot:prepend>
      <v-spacer />
      <v-row class="ma-1 position fixed">
        <v-col cols="2" class="mr-1">
          <v-avatar
            variant="elevated"
            class="primary--text v-sheet--outlined"
            color="background"
          >
            <v-img v-if="logoURL" :src="logoURL" alt="logo" />
            <div v-else>
              {{ accountInitials }}
            </div>
          </v-avatar>
        </v-col>
        <v-col>
          <change-stripe-account-selector
            @updateInitials="handleInitialsUpdate"
          />
        </v-col>
      </v-row>
      <v-row class="justify-center my-3">
        <v-divider length="12rem" />
      </v-row>
    </template>
    <!-- Menu Options -->
    <template v-slot:default>
      <v-list
        class="text-grey-darken-1 text-title-1 pa-0"
        v-for="item in menu"
        :key="item.title"
      >
        <v-list-item
          v-if="!item.subgroup"
          v-hasRoleAccessToRoute="{ pathName: item.to }"
          @click="!item.disabled ? onMenuClick(item) : ''"
          :title="$t(`platform.menu.${item.title}`)"
        >
          <template v-if="item.disabled" v-slot:prepend>
            <v-tooltip :text="$t('toolTipsMessage.restricted_feature_message')">
              <template v-slot:activator="{ props }">
                <v-icon
                  :color="item.iconColor"
                  v-bind="props"
                  :icon="item.icon"
                />
              </template>
            </v-tooltip>
          </template>
          <template v-else v-slot:prepend>
            <v-icon :icon="item.icon" />
          </template>
        </v-list-item>
        <v-list-group v-else :value="item.title">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              :title="$t(`platform.menu.${item.title}`)"
            >
              <template v-slot:prepend>
                <v-icon>{{ item.icon }}</v-icon>
              </template>
            </v-list-item>
          </template>
          <v-list-item
            v-for="subgroupItem in item.subgroup"
            :key="subgroupItem.title"
            v-hasRoleAccessToRoute="{ pathName: subgroupItem.to }"
            @click="!subgroupItem.disabled ? onMenuClick(subgroupItem) : ''"
            :title="$t(`platform.menu.${subgroupItem.title}`)"
            style="padding-left: 30px !important"
          >
            <template v-if="subgroupItem.disabled" v-slot:prepend>
              <v-tooltip
                :text="$t('toolTipsMessage.restricted_feature_message')"
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    :color="subgroupItem.iconColor"
                    v-bind="props"
                    :icon="subgroupItem.icon"
                  />
                </template>
              </v-tooltip>
            </template>
            <template v-else v-slot:prepend>
              <v-icon :color="subgroupItem.iconColor">{{
                subgroupItem.icon
              }}</v-icon>
            </template>
          </v-list-item>
        </v-list-group>
      </v-list>
    </template>

    <!-- Navigation Drawer Footer -->
    <template v-slot:append>
      <v-list class="mb-16 text-grey-darken-1 text-title-1">
        <v-row class="justify-center my-3">
          <v-divider length="12rem" color="grey-darken-4 mb-3" />
        </v-row>
        <v-list-item
          link
          :href="config?.links.helpLink"
          target="_blank"
          prepend-icon="mdi-headset"
          :title="$t('platform.menu.help')"
        >
        </v-list-item>
        <v-list-item
          link
          @click="useAuthStore().logout()"
          prepend-icon="mdi-logout"
          :title="$t('platform.menu.logout')"
        >
        </v-list-item>
        <v-row class="mt-5" no-gutters>
          <v-col class="d-flex justify-center">
            <v-img
              src="@/assets/HivewayLogo.png"
              max-height="200px"
              max-width="100%"
              min-height="90px"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="text-end">
          <v-col class="d-flex justify-center">
            <v-card-subtitle @click="openModal">
              {{ appVersion }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-list>
      <v-dialog v-model="modalOpen" width="60%" height="85%">
        <v-card>
          <v-row>
            <v-col class="d-flex" style="justify-content: flex-start">
              <v-img
                src="@/assets/HivewayLogo.png"
                max-height="90px"
                max-width="25%"
                min-height="90px"
              />
            </v-col>
          </v-row>
          <v-divider class="pb-4"></v-divider>

          <v-row>
            <v-col>
              <v-card-title>
                <span>Release Notes</span>
              </v-card-title>
            </v-col>
            <v-col md="auto" class="pr-9">
              <v-select
                v-model="selectedVersion"
                :items="versions"
                label="Version"
                class="ml-auto"
              ></v-select>
            </v-col>
          </v-row>

          <v-card-text :isLoading="loading">
            <div style="max-height: 55vh; overflow-y: auto">
              <div v-html="markdownContent"></div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="modalOpen = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect, onMounted } from "vue";
import { useAccountStore } from "@/stores/account";
import { useMenuStore } from "@/stores/global";
import { useAuthStore } from "@/stores/auth";
import { PlatformFeature } from "@/enums/feature.enum";
import { AccountPlan } from "@/enums/status.enum";
import { getInitials } from "@/utils/initials.utils";
import ChangeStripeAccountSelector from "@/components/ChangeStripeAccountSelector.vue";
import config from "@/configs";
import { useRouter } from "vue-router";
import { useConfluenceStore } from "@/stores/confluence";
import { ReleaseNotes } from "@/enums/releaseNotes.enum";

const accountInitials = ref("");
const router = useRouter();
const appVersion = ref(import.meta.env.APP_VERSION);
const accountPlan = ref("");
const modalOpen = ref(false);
const markdownContent = ref("");
const selectedVersion = ref("");
const versions = ref<string[]>([]); // Example versions
const loading = ref(false);

const handleInitialsUpdate = (accountName: any) => {
  accountInitials.value = getInitials(accountName);
};

watchEffect(() => {
  accountPlan.value = useAuthStore().accountPlan;
});

const allMenus = [
  {
    title: PlatformFeature.DASHBOARD,
    icon: "mdi-view-dashboard-outline",
    to: "dashboard",
    order: 1,
    iconColor: "",
    disabled: false,
  },
  {
    title: PlatformFeature.PAYMENTS,
    icon: "mdi-credit-card-multiple-outline",
    to: "payments",
    order: 2,
    iconColor: "",
    disabled: false,
  },
  {
    title: PlatformFeature.TERMINALS,
    icon: "mdi-cellphone-cog",
    order: 3,
    iconColor: "",
    disabled: false,
    subgroup: [
      {
        title: PlatformFeature.FLEET_MANAGEMENT,
        icon: "mdi-hexagon-multiple-outline",
        to: "readers",
        iconColor: "",
        disabled: false,
      },
      {
        title: PlatformFeature.FORMS,
        icon: "mdi-note-edit-outline",
        to: "terminalForms",
        iconColor: "",
        disabled: false,
      },
    ],
  },
  {
    title: PlatformFeature.CUSTOMERS,
    icon: "mdi-account-group-outline",
    to: "customers",
    order: 4,
    iconColor: "",
    disabled: false,
  },
  {
    title: PlatformFeature.PAYMENT_LINKS,
    icon: "mdi-vector-link",
    to: "paymentLinks",
    order: 5,
    iconColor: "",
    disabled: false,
  },
  {
    title: PlatformFeature.PRODUCTS,
    icon: "mdi-package-variant",
    to: "products",
    order: 6,
    iconColor: "",
    disabled: false,
  },
  {
    title: PlatformFeature.USERS,
    icon: "mdi-account-supervisor-circle-outline",
    to: "users",
    order: 7,
    iconColor: "",
    disabled: false,
  },
  // TODO: add account management and settings back in after we unlock the surcharge feature
  // {
  //   title: PlatformFeature.ACCOUNT,
  //   icon: "mdi-bank",
  //   order: 8,
  //   iconColor: "",
  //   disabled: false,
  //   subgroup: [
  //     {
  //       title: PlatformFeature.MANAGEMENT,
  //       icon: "mdi-hexagon-multiple-outline",
  //       to: "account",
  //       iconColor: "",
  //       disabled: false,
  //     },
  //     {
  //       title: PlatformFeature.SETTINGS,
  //       icon: "mdi-cog-outline",
  //       to: "settings",
  //       iconColor: "",
  //       disabled: false,
  //     },
  //   ],
  // },

  {
    title: PlatformFeature.ACCOUNT,
    icon: "mdi-bank",
    order: 8,
    iconColor: "",
    disabled: false,
    to: "account",
  },
];

// handle menu based on account planx
const menu = computed(() => {
  if (accountPlan.value === AccountPlan.STARTER) {
    const restrictedMenu = allMenus.map((item) => {
      if (
        item.to === "paymentLinks" ||
        item.to === "products" ||
        item.to === "customers"
      ) {
        return {
          ...item,
          disabled: true,
          icon: "mdi-lock-outline",
          iconColor: "light-blue",
        };
      }

      if (item.subgroup) {
        const restrictedSubgroup = item.subgroup.map((subgroupItem) => {
          if (subgroupItem.to === "terminalForms") {
            return {
              ...subgroupItem,
              disabled: true,
              icon: "mdi-lock-outline",
              iconColor: "light-blue",
            };
          }
          return subgroupItem;
        });
        return {
          ...item,
          subgroup: restrictedSubgroup,
        };
      }

      return item;
    });
    return restrictedMenu;
  }
  return allMenus;
});

const logoURL = computed(() => {
  return useAccountStore().account?.logoURL || "";
});

const onMenuClick = (item: any) => {
  router.push({ name: `${item.to}` });
};

const openModal = () => {
  selectedVersion.value = versions.value[0];
  loadConfluenceContent(selectedVersion.value);
  modalOpen.value = true;
};

const loadConfluenceContent = async (version: string) => {
  // Replace this with the actual path to your markdown files
  loading.value = true;
  markdownContent.value = await useConfluenceStore().getResource(
    ReleaseNotes[version as keyof typeof ReleaseNotes]
  );
  loading.value = false;
};

watchEffect(() => {
  if (selectedVersion.value) {
    loadConfluenceContent(selectedVersion.value);
  }
});

onMounted(() => {
  versions.value = Object.keys(ReleaseNotes);
  selectedVersion.value = versions.value[0];
  loadConfluenceContent(selectedVersion.value);
});
</script>
