import { CartStore, CartItem } from "@/types/cart";
import { defineStore } from "pinia";
import router from "@/router";
import axios from "@/plugins/axios";
import { useSnackBarStore } from "@/stores/snackbar";

export const useCartStore = defineStore("cart", {
  state: (): CartStore => ({
    loading: false,
    isVisible: false,
    items: [] as CartItem[],
    isAnimating: false,
  }),

  getters: {
    total(): number {
      return this.items.reduce(
        (sum: number, item: CartItem) => sum + item.quantity * item.price,
        0
      );
    },
  },

  actions: {
    triggerAnimation() {
      this.isAnimating = true;
      setTimeout(() => {
        this.isAnimating = false;
      }, 200);
    },

    removeItem(itemId: string) {
      const index = this.items.findIndex((item: any) => item.id === itemId);
      if (index !== -1) {
        this.items.splice(index, 1);
        this.triggerAnimation();
      }
    },

    toggleIsCartVisible() {
      this.isVisible = !this.isVisible;
    },

    updateItemQuantity(itemId: string, change: number, priceId: string) {
      const item = this.items.find(
        (item: any) => item.id === itemId && item.priceId === priceId
      );
      if (item) {
        if (change < 0 && item.quantity === 1) {
          return;
        }
        item.quantity = Math.max(1, item.quantity + change);
        this.triggerAnimation();
      }
    },

    resetCart() {
      this.triggerAnimation();
      this.items = [];
    },

    async checkoutCart() {
      this.loading = true;
      try {
        const response = await axios.post("/cart", this.items);
        if (response.data.paymentIntentId) {
          //redirect to payment process page
          router.push({
            name: "processPayment",
            params: {
              id: response.data.paymentIntentId,
            },
          });
        }
      } catch (error: any) {
        useSnackBarStore().toastError({ message: error.data.error });
      } finally {
        this.loading = false;
        this.resetCart();
      }
    },

    addItem(item: any) {
      let cartItem: any;
      if (item.relatedProduct) {
        cartItem = {
          id: item.relatedProduct.id,
          name: item.relatedProduct.name,
          quantity: 1,
          description: item.relatedProduct.description,
          price: item.unit_amount,
          image: item.relatedProduct.images[0],
          priceName: item?.nickname,
          priceId: item?.id,
          currency: item?.currency,
        };
      } else {
        cartItem = {
          id: item.id,
          name: item.name,
          quantity: 1,
          description: item.description,
          price: item.default_price?.unit_amount
            ? item.default_price?.unit_amount
            : item.unit_amount,
          image: item.images[0],
          priceName: item.default_price?.nickname
            ? item.default_price?.nickname
            : item.nickname,
          priceId: item.default_price?.id ? item.default_price?.id : item.id,
          currency: item.default_price?.currency
            ? item.default_price?.currency
            : item.currency,
        };
      }
      const itemInCart = this.items?.find(
        (i) => i.id === cartItem.id && i.priceId === cartItem.priceId
      );

      if (itemInCart) {
        itemInCart.quantity = itemInCart.quantity + 1;
        this.triggerAnimation();
      } else {
        this.items.push(cartItem);
        this.triggerAnimation();
      }
    },

    async getCartData(paymentIntentId: string) {
      this.loading = true;
      try {
        const response = await axios.get(`/cart/${paymentIntentId}`);
        this.items = response.data;
        useSnackBarStore().toastSuccess({ message: "Cart items loaded" });
        return response.data;
      } catch (error: any) {
        useSnackBarStore().toastError({ message: error.data.error });
      } finally {
        this.loading = false;
      }
    },
  },
});
