const getSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

const setSessionStorage = (key: string, value: string) => {
  return sessionStorage.setItem(key, value);
};

const removeSessionStorage = (key: string) => {
  return sessionStorage.removeItem(key);
};

export const setCurrentAccount = (account: {
  role?: string;
  plan?: string;
  accessStatus?: string;
}) => {
  setSessionStorage("currentAccount", JSON.stringify(account));
};

export const getCurrentAccount = () => {
  const account = getSessionStorage("currentAccount");
  return account ? JSON.parse(account) : null;
};

export const removeCurrentAccount = () => {
  removeSessionStorage("currentAccount");
};

export const updateCurrentAccount = (account: any) => {
  setCurrentAccount(account);
};
